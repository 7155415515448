import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./login.css";
import otpLandingImg from "../../assets/Login-rafiki 1 (2).png";
import OtpLoader from "./OtpLoader/OtpLoader";

const OtpSection = (props) => {
  return (
    <Row className="py-md-2 justify-content-between">
      <Col md={6} sm={12} className="d-none d-md-flex justify-content-center">
        <img
          className="img-fluid landing-image"
          src={otpLandingImg}
          alt="landing-Image"
        />
      </Col>
      {/* <Col md={1} className="d-none d-md-block p-0 border_left"></Col> */}
      <Col
        md={6}
        sm={12}
        className="mt-md-4 d-flex justify-content-center justify-content-md-start"
      >
        <div className="d-none d-md-block border_left"></div>
        <div className="pt-5 input-content-div">
          <p style={{ fontWeight: "700", fontSize: "34px" }}>Verification</p>
          <span
            style={{
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
            }}
          >
            Please Enter the OTP Sent to Your Mobile Number{" "}
          </span>
          <Form onSubmit={props.onSubmitOTP}>
            <Form.Group className="my-4" controlId="formBasicEmail">
              <Form.Control
                className="sign-in-form"
                size="lg"
                type="number"
                placeholder="Otp code"
                value={props.otp}
                onChange={(e) => props.setOtp(e.target.value)}
                required
              />
            </Form.Group>
            {props.afterOtpLoading ? <OtpLoader></OtpLoader> : <h4></h4>}
            <Button type="submit" className="sign-in-btn mt-2">
              Verifiy
            </Button>
            <p className="pt-2 fst-italic text-end">
              {props.timer < 10 ? `00:0${props.timer}` : `00:${props.timer}`}
            </p>
          </Form>
          <div
            className="mt-2"
            style={{
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "30px",
            }}
          >
            <span>
              Don’t Received an OTP?{" "}
              {props.resendStatus === false ? (
                <span
                  //className="change-text-color"
                  style={{ color: "#B2BEB5" }}
                  //onClick={props.resendOTP}
                >
                  Resend OTP
                </span>
              ) : (
                <span
                  className="change-text-color"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={props.resendOTP}
                >
                  Resend OTP
                </span>
              )}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default OtpSection;
