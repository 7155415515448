import {
  QUIZ_LIST_REQUEST,
  QUIZ_LIST_SUCCESS,
  QUIZ_LIST_FAIL,
  SUBMIT_ANSWER_REQUEST,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAIL,
} from "../type";

export const quizListReducer = (state = { quiz: {} }, action) => {
  switch (action.type) {
    case QUIZ_LIST_REQUEST:
      return { ...state, loading: true };
    case QUIZ_LIST_SUCCESS:
      return { loading: false, quiz: action.payload };
    case QUIZ_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const submitAnswerReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_ANSWER_REQUEST:
      return { submitLoading: true };
    case SUBMIT_ANSWER_SUCCESS:
      return {
        submitLoading: false,
        message: action.payload,
      };
    case SUBMIT_ANSWER_FAIL:
      return {
        submitLoading: false,
        submitError: action.payload,
      };
    default:
      return state;
  }
};
