import React from "react";
import "./OtpLoader.css";

const OtpLoader = () => {
  return (
    <div>
      <div className="cs-loader">
        <div className="cs-loader-inner">
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
          <label>●</label>
        </div>
      </div>
    </div>
  );
};

export default OtpLoader;
