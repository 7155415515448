import {
  QUIZ_LIST_REQUEST,
  QUIZ_LIST_SUCCESS,
  QUIZ_LIST_FAIL,
  SUBMIT_ANSWER_REQUEST,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAIL,
} from "../type";
import axios from "axios";

export const getQuizList = () => async (dispatch) => {
  try {
    dispatch({
      type: QUIZ_LIST_REQUEST,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/careboxQuiz/v1/quiz-question-list/`,
      config
    );

    dispatch({
      type: QUIZ_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QUIZ_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const submitQuizAnswer = (body) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_ANSWER_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/careboxQuiz/v1/submit-answer/`,
      body,
      config
    );

    dispatch({
      type: SUBMIT_ANSWER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBMIT_ANSWER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
