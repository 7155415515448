import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./Routes/PrivateRoute";
import Register from "./components/LoginPage/register";
import Login from "./components/LoginPage/login";
import Home from "./components/Home/home";
import SuccessPage from "./components/ResponsePage/SuccessPage";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/register" element={<Register></Register>} />
        </Routes>
        <Routes>
          <Route path="/login" element={<Login></Login>} />
        </Routes>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home></Home>
              </PrivateRoute>
            }
          />
        </Routes>
        <Routes>
          <Route path="/response-form" element={<SuccessPage></SuccessPage>} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
