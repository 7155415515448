import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import careboxLogo from "../../assets/c1-11 1.png";
import loginLandingImage from "../../assets/Login-rafiki 1 (1).png";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./login.css";
import { login } from "../../redux/actions/UserActions";
import axios from "axios";
import OtpLoader from "./OtpLoader/OtpLoader";
import OtpSection from "./OtpSection";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [numberdisplay, setNumberdisplay] = useState("block");
  const [otpdisplay, setOtpdisplay] = useState(false);
  const [message, setMessage] = useState("**Must use (+88) country code");
  const [number, setNumber] = useState("+88");
  const [otp, setOtp] = useState("");
  //console.log(otp);
  const [messageshow, setMessageshow] = useState(false);
  const [otpsuccess, setOtpsuccess] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [afterOtpLoading, setAfterOtpLoading] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  const [submitNumber, setSubmitNumber] = useState(false);
  const [timer, setTimer] = useState(59);
  const [resendStatus, setResendStatus] = useState(false);
  const [requestForOtp, setRequestForOtp] = useState(0);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  useEffect(() => {
    if (error) {
      console.log(error);
      if (error.message) {
        alert(error.message);
        setOtpLoading(false);
      } else if (error.Phone) {
        //console.log("Phone");
        alert(error.Phone);
        setOtpLoading(false);
      } else {
        //console.log("diifff");
        setOtpLoading(false);
      }
    } else if (
      submitNumber &&
      userInfo?.message === "User found, please verify OTP." &&
      userInfo?.otp_status === "sent"
    ) {
      setNumberdisplay("none");
      setOtpdisplay(true);
      setSubmitNumber(false);
      setOtpLoading(false);
      handleTimer();
      //console.log("masking", userInfo?.otp_status);
    }
  }, [error, submitNumber, userInfo]);

  let otp_form_data = new FormData();
  otp_form_data.append("Phone", number);
  otp_form_data.append("otp", otp);

  // if (userInfo) {
  //   console.log(userInfo);
  // }

  //console.log(otp_form_data);
  const onSignInSubmit = (e) => {
    e.preventDefault();
    setMessageshow(false);
    setSubmitNumber(true);
    // this is connected from redux and call the register dispatch
    dispatch(login(number));
    setOtpLoading(true);
    // if (!number.match(validateNumber)) {
    //   setOtpLoading(false);
    //   setMessageshow(true);
    // } else if (registerError === true) {
    //   setOtpLoading(false);
    //   alert("user with this Phone already exists.");
    // } else {

    //   // loading ? <OtpLoader></OtpLoader> :
    // }
  };

  const onSubmitOTP = (e) => {
    e.preventDefault();
    setOtpLoading(false);
    setAfterOtpLoading(true);
    if (userInfo.otp_status === "sent") {
      //console.log("Masking Service");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/user/otp_login/`,
          otp_form_data
        )
        .then((res) => {
          localStorage.setItem("access_token", res.data.access);
          localStorage.setItem("refresh_token", res.data.refresh);
          //console.log( "this is login:", res.data);
          //localStorage.setItem("success", "");
          localStorage.setItem("registerd", "done");
          setOtpLoading(false);
          // if (localStorage.getItem("url")) {
          //   history.push(localStorage.getItem("url"));
          //   localStorage.removeItem("url");
          // } else {
          //   history.replace(from);
          // }
          navigate("/");
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
            setAfterOtpLoading(false);
            setOtpLoading(false);
            console.log("error message:", err.response.data.message);
          } else {
            console.log(err.message);
            alert("Something went Wrong.");
            setAfterOtpLoading(false);
            setOtpLoading(false);
          }
        });
    }
  };

  // resend otp*
  const resendOTP = () => {
    setRequestForOtp((prev) => prev + 1);
    dispatch(login(number));
    handleTimer();
  };

  const ref = useRef(null);

  // useEffect(() => {
  //   ref.current.focus();
  // }, []);
  //console.log(requestForOtp);
  const handleTimer = () => {
    setResendStatus(false);
    let interval = setInterval(() => {
      setTimer((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setResendStatus(true);
          //setStatus("finished");
          //setCurrentValue("");
          //setNumberOfTest(numberOfTest + 1);
          return timer;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);
  };
  //console.log(userInfo);
  return (
    <div style={{ minHeight: "100vh", overflow: "hidden" }}>
      <div className="d-flex justify-content-center my-4">
        <img src={careboxLogo} alt="logo-img" className="logo-image" />
      </div>
      <div className="mt-5 content-section">
        <Row className="pt-5 text-center">
          <p style={{ fontWeight: "700", fontSize: "34px" }}>
            Welcome to Online Quiz Portal
          </p>
        </Row>
        {otpdisplay ? (
          <OtpSection
            onSubmitOTP={onSubmitOTP}
            otp={otp}
            setOtp={setOtp}
            afterOtpLoading={afterOtpLoading}
            resendStatus={resendStatus}
            resendOTP={resendOTP}
            timer={timer}
          />
        ) : (
          <Row className="py-md-2 justify-content-between">
            <Col
              md={6}
              sm={12}
              className="d-none d-md-flex justify-content-center"
            >
              <img
                className="img-fluid landing-image"
                src={loginLandingImage}
                alt="landing-Image"
              />
            </Col>
            {/* <Col md={1} className="d-none d-md-block p-0 border_left"></Col> */}
            <Col
              md={6}
              sm={12}
              className="mt-md-4 d-flex justify-content-center justify-content-md-start"
            >
              <div className="d-none d-md-block border_left"></div>
              <div className="pt-5 input-content-div">
                <p style={{ fontWeight: "700", fontSize: "34px" }}>Sign In</p>
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "27px",
                  }}
                >
                  Please enter your phone number to
                  <span className="change-text-color"> sign in.</span>{" "}
                </span>
                <Form onSubmit={onSignInSubmit}>
                  <Form.Group
                    className="sign-in-form mt-5"
                    controlId="formBasicEmail"
                  >
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="Phone Number"
                      value={number}
                      onChange={(e) => {
                        setNumber(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                  <span
                    style={{
                      color: "#CC0A61",
                    }}
                  >
                    {message}
                  </span>
                  {otpLoading ? <OtpLoader /> : <h4></h4>}
                  <Button type="submit" className="mt-3 sign-in-btn">
                    Sign In
                  </Button>
                </Form>
                <div
                  className="mt-4 mx-3"
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  <span>
                    Don't have an account?{" "}
                    <span
                      className="change-text-color"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => navigate("/register")}
                    >
                      Sign up
                    </span>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Login;
