import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import careboxLogo from "../../assets/c1-11 1.png";
import { useNavigate } from "react-router-dom";
import "../LoginPage/login.css";
import responseImage from "../../assets/Group.png";
import { Button } from "react-bootstrap";
import { logout } from "../../redux/actions/UserActions";

const SuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      //console.log("inside");
      dispatch(logout());
    }
  }, []);
  return (
    <div style={{ minHeight: "100vh", overflow: "hidden" }}>
      <div className="d-flex justify-content-center my-4">
        <img src={careboxLogo} alt="logo-img" className="logo-image" />
      </div>
      <div className="mt-5 content-section">
        <div className="pt-5 d-flex justify-content-center">
          <img
            src={responseImage}
            alt="response-img"
            className="response-img"
          />
        </div>
        <div className="pt-5 d-flex justify-content-center">
          <span className="thank-you-text">THANK YOU</span>
        </div>
        <div className="pt-2 text-center">
          <span className="disable-text">
            We've received your submission, and we'll be in touch soon!
          </span>
        </div>
        <div className="py-3 d-flex justify-content-center">
          <Button
            type="submit"
            className="explore-btn"
            onClick={() => window.location.replace("https://www.care-box.com/")}
          >
            Keep Exploring
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
