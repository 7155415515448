import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import quizImage from "../../assets/quize upeer style-01 1.png";
import userIcon from "../../assets/user.png";
import callIcon from "../../assets/call.png";
import playStoreImg from "../../assets/app-play.png";
import appleStoreImg from "../../assets/app-ios.png";
import "./home.css";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { getUserDetails } from "../../redux/actions/UserActions";
import { getQuizList, submitQuizAnswer } from "../../redux/actions/QuizActions";
import OtpLoader from "../LoginPage/OtpLoader/OtpLoader";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, quiz, error } = useSelector((state) => state.quizList);
  const { submitLoading, message, submitError } = useSelector(
    (state) => state.submitAnswer
  );
  const [firstQuestionAnswer, setFirstQuestionAnswer] = useState();
  const [secondQuestionAnswer, setSecondQuestionAnswer] = useState();
  const [thirdQuestionAnswer, setThirdQuestionAnswer] = useState();
  const [schoolName, setSchoolName] = useState("");
  const [className, setClassName] = useState("");
  const [code, setCode] = useState("");
  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getQuizList());
  }, [dispatch]);

  useEffect(() => {
    if (alertMessage && submitError !== undefined && message === undefined) {
      setAlertMessage(false);
      console.log(submitError);
      if (submitError.message) {
        alert(submitError.message);
      }
    }
    if (alertMessage && message !== undefined) {
      setAlertMessage(false);
      navigate("/response-form");
    }
  }, [alertMessage, submitError, message]);

  const postQuizAnswer = () => {
    //e.preventDefault();
    if (
      code !== "" &&
      firstQuestionAnswer &&
      secondQuestionAnswer &&
      thirdQuestionAnswer &&
      schoolName !== "" &&
      className !== ""
    ) {
      const body = {
        code: code,
        school_name: schoolName,
        class_name: className,
        questions: [
          {
            question_id: 1,
            submitted_option: parseInt(firstQuestionAnswer),
          },
          {
            question_id: 2,
            submitted_option: parseInt(secondQuestionAnswer),
          },
          {
            question_id: 3,
            submitted_option: parseInt(thirdQuestionAnswer),
          },
        ],
      };
      dispatch(submitQuizAnswer(body));
      setAlertMessage(true);
    } else if (schoolName === "") {
      alert("Please Write Down Your School Name");
    } else if (className === "") {
      alert("Please Write Down Your Class");
    } else if (code === "") {
      alert("Please Write Down Coupon Code From App");
    } else {
      alert("Select Answers for Required Questions");
    }
  };
  const handleAppIos = () => {
    window.open(
      "https://apps.apple.com/us/app/care-box-all-cares-inside/id1610855749",
      "_blank"
    );
  };
  const handleAppPlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=care_box.carebox.com",
      "_blank"
    );
  };
  //console.log(quiz);
  return (
    <div style={{ minHeight: "100vh", overflow: "hidden" }}>
      <div>
        <img src={quizImage} alt="quizImage" className="img-fluid" />
        <div className="border-down"></div>
      </div>
      <div className="main-section pb-5">
        <Container>
          <Row
            className="py-2 px-2"
            style={{ fontWeight: "600", fontSize: "25px" }}
          >
            Your Personal Information
          </Row>
          <Row className="py-2">
            <Col lg={6} md={12} className="mb-2 mb-lg-0">
              <div className="information-card">
                <img src={userIcon} alt="userIcon" height={24} width={24} />{" "}
                {userDetails?.user.Name}
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="information-card">
                <img src={callIcon} alt="userIcon" height={24} width={24} />{" "}
                {userDetails?.user.Phone}
              </div>
            </Col>
          </Row>
          <Row className="py-2">
            <Col lg={6} md={12} className="mb-2 mb-lg-0">
              <FloatingLabel
                controlId="floatingInputGrid"
                label="School/College Name"
              >
                <Form.Control
                  type="text"
                  placeholder="School/College Name"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col lg={6} md={12}>
              <FloatingLabel controlId="floatingInputGrid" label="Your Class">
                <Form.Control
                  type="text"
                  placeholder="Your Class"
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row
            className="py-2 px-2"
            style={{ fontWeight: "600", fontSize: "25px" }}
          >
            Let's Test your knowledge
          </Row>
          <Row className="py-2 px-2">
            <div className="quiz-card">
              <p
                className="home-text"
                style={{ fontWeight: "500", fontSize: "20px" }}
              >
                {quiz.length !== undefined ? quiz[0].question : "..."}
              </p>
              <div>
                <Form.Check
                  //className="pt-1"
                  type="checkbox"
                  id={1}
                  label={quiz.length !== undefined ? quiz[0].option_a : ""}
                  onChange={(e) => setFirstQuestionAnswer(e.target.id)}
                  checked={firstQuestionAnswer == 1 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={2}
                  label={quiz.length !== undefined ? quiz[0].option_b : ""}
                  onChange={(e) => setFirstQuestionAnswer(e.target.id)}
                  checked={firstQuestionAnswer == 2 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={3}
                  label={quiz.length !== undefined ? quiz[0].option_c : ""}
                  onChange={(e) => setFirstQuestionAnswer(e.target.id)}
                  checked={firstQuestionAnswer == 3 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={4}
                  label={quiz.length !== undefined ? quiz[0].option_d : ""}
                  onChange={(e) => setFirstQuestionAnswer(e.target.id)}
                  checked={firstQuestionAnswer == 4 ? true : false}
                />
              </div>
            </div>
          </Row>
          <Row className="py-2 px-2">
            <div className="quiz-card">
              <p
                className="home-text"
                style={{ fontWeight: "500", fontSize: "20px" }}
              >
                {quiz.length !== undefined ? quiz[1].question : ""}
              </p>
              <div>
                <Form.Check
                  //className="pt-1"
                  type="checkbox"
                  id={1}
                  label={quiz.length !== undefined ? quiz[1].option_a : ""}
                  onChange={(e) => setSecondQuestionAnswer(e.target.id)}
                  checked={secondQuestionAnswer == 1 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={2}
                  label={quiz.length !== undefined ? quiz[1].option_b : ""}
                  onChange={(e) => setSecondQuestionAnswer(e.target.id)}
                  checked={secondQuestionAnswer == 2 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={3}
                  label={quiz.length !== undefined ? quiz[1].option_c : ""}
                  onChange={(e) => setSecondQuestionAnswer(e.target.id)}
                  checked={secondQuestionAnswer == 3 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={4}
                  label={quiz.length !== undefined ? quiz[1].option_d : ""}
                  onChange={(e) => setSecondQuestionAnswer(e.target.id)}
                  checked={secondQuestionAnswer == 4 ? true : false}
                />
              </div>
            </div>
          </Row>
          <Row className="py-2 px-2">
            <div className="quiz-card">
              <p
                className="home-text"
                style={{ fontWeight: "500", fontSize: "20px" }}
              >
                {quiz.length !== undefined ? quiz[2].question : ""}
              </p>
              <div>
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={1}
                  label={quiz.length !== undefined ? quiz[2].option_a : ""}
                  onChange={(e) => setThirdQuestionAnswer(e.target.id)}
                  checked={thirdQuestionAnswer == 1 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={2}
                  label={quiz.length !== undefined ? quiz[2].option_b : ""}
                  onChange={(e) => setThirdQuestionAnswer(e.target.id)}
                  checked={thirdQuestionAnswer == 2 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={3}
                  label={quiz.length !== undefined ? quiz[2].option_c : ""}
                  onChange={(e) => setThirdQuestionAnswer(e.target.id)}
                  checked={thirdQuestionAnswer == 3 ? true : false}
                />
                <Form.Check
                  className="pt-1"
                  type="checkbox"
                  id={4}
                  label={quiz.length !== undefined ? quiz[2].option_d : ""}
                  onChange={(e) => setThirdQuestionAnswer(e.target.id)}
                  checked={thirdQuestionAnswer == 4 ? true : false}
                />
              </div>
            </div>
          </Row>
          <Row className="py-3">
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "1000", fontSize: "20px" }}>
                To get the coupon code, Download the App from{" "}
                <img
                  src={playStoreImg}
                  alt="playStoreImg"
                  height={33}
                  onClick={handleAppPlay}
                  style={{ cursor: "pointer" }}
                />{" "}
                Or{" "}
                <img
                  src={appleStoreImg}
                  alt="appleStoreImg"
                  height={33}
                  style={{ cursor: "pointer" }}
                  onClick={handleAppIos}
                />{" "}
              </Form.Label>
              <Form.Control
                className="mt-1 coupon-input"
                size="lg"
                type="text"
                placeholder="Insert Coupon"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row className="d-none py-2">
            <p className="pt-2 home-text" style={{ fontWeight: "800" }}>
              NB: Download & open Care-box App, get a Code to use in this field.
            </p>
            {/* <p className="home-text" style={{ fontWeight: "800" }}>
              Click Here To Download..{" "}
              <img
                src={playStoreImg}
                alt="playStoreImg"
                height={33}
                onClick={handleAppPlay}
                style={{ cursor: "pointer" }}
              />{" "}
              Or{" "}
              <img
                src={appleStoreImg}
                alt="appleStoreImg"
                height={33}
                style={{ cursor: "pointer" }}
                onClick={handleAppIos}
              />
            </p> */}
          </Row>
          {submitLoading ? <OtpLoader></OtpLoader> : <></>}
          <Row className="py-5 px-3">
            <Button className="quiz-submit-btn" onClick={postQuizAnswer}>
              Submit
            </Button>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Home;
